import { baseApi } from "./apiSingleton";

// Define the response interface for initializing ship content
export interface IGetShipContentResponse {
  [x: string]: any;
  grades: Array<{
    code: string;
    name: string;
    meta_category: string;
    description: Array<{ description: string }>;
    valid_from: typeof Date;
    valid_to: typeof Date;
    cabin_qty: number;
    colour_code: string;
    images: Array<{
      image: string;
      size: string;
    }>;
  }>;
}

// Define the request interface for initializing ship content
export interface IGetShipContentRequest {
  ship_code: string;
  language: string;
}

// Define the BaseAPI with an endpoint to retreive content for different entities
export const ContentAPI = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getShipContent: builder.query<
      IGetShipContentResponse, // Response data type
      IGetShipContentRequest // Request data type
    >({
      query: ({ ship_code, language }) => ({
        url: `/avl/ships/${ship_code}/?language=${language}`,
        method: "GET",
      }),
    }),
  }),
});

// Extract the hook for retreiving the ship content
export const { useLazyGetShipContentQuery } = ContentAPI;
