import { useTranslation } from "react-i18next"; // Importing the useTranslation hook from react-i18next library.

import { useTypedDispatch, useTypedSelector } from "../../../../../store/store"; // Importing the useTypedSelector hook from the store.
import { Room } from "../../../../../store/slices/roomsSlice"; // Importing the Room type from the roomsSlice slice.
import { Deck } from "../../../../../store/slices/searchSlice"; // Importing the Deck type from the searchSlice slice.
import { parseMarkdownWithLink } from "../../../../../utils/helpers/parseMarkdown";
import { showModal } from "../../../../../store/slices/modalSlice";
import MODAL from "../../../../../utils/constants/modal";

import LoadingContainer from "../../../../containers/LoadingContainer"; // Importing the LoadingContainer component from the containers directory.
import CustomSelect from "../../../../shared/CustomSelect"; // Importing the CustomSelect component from the shared directory.

import styles from "./index.module.scss"; // Importing the local CSS module for styling.
import Button from "../../../../shared/Button";

// Defining the interface for the DeckPlans component props.
interface IDeckPlansProps {
  room?: Room; // Optional property for the room information.
  decks?: Array<{ value: string; label: string }>; // Optional array of deck options.
  handleChoseDeck: (room: Room) => void; // Function to handle deck selection.
}

// Defining the DeckPlans functional component with props.
function DeckPlans({ room, decks, handleChoseDeck }: IDeckPlansProps) {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation(); // Using the useTranslation hook to access translation functions.

  const { cruise } = useTypedSelector((state) => state.search); // Extracting cruise information from the store using useTypedSelector.
  const { isCabinsLoading, cabins } = useTypedSelector((state) => state.rooms); // Extracting loading state and cabin information from the store.

  const { full_deck_plan_link, deck_legend } = useTypedSelector(
    (state) => state.environment,
  );

  const fullDeckPlan = parseMarkdownWithLink(full_deck_plan_link)[0];

  // Function to handle deck selection change.
  const handleDeckChange = ({ value }: { value: string }) => {
    // Finding the selected deck based on its code.
    const deck: Deck | undefined = cruise?.ship.decks.find(
      // Matching the deck code with the selected value.
      (el) => el.code === value,
    );

    // Creating a deep copy of the room with the updated deck.
    const updatedStateroom = structuredClone({ ...room, deck });

    // Calling the handleChoseDeck function with the updated room.
    handleChoseDeck(updatedStateroom);
  };

  const showDeckPlansModal = () => {
    dispatch(
      showModal({
        size: "lg",
        type: MODAL.MODAL_TYPES.DECK_PLANS,
        data: fullDeckPlan?.url,
      }),
    );
  };

  return (
    <LoadingContainer isLoading={isCabinsLoading}>
      {/* Rendering the LoadingContainer component with loading state. */}
      {/* Container div with styles from local CSS module. */}
      <div className={styles.container}>
        {/* Paragraph displaying the translated text for "deck plans". */}
        <div>
          <p className={styles.title}>{t("deck plans")}</p>

          {fullDeckPlan?.text && fullDeckPlan?.url && (
            <Button
              className={styles.fullDeckTitle}
              label={fullDeckPlan?.text}
              variant="link"
              onClick={showDeckPlansModal}
            />
          )}
        </div>

        {cabins?.length ? ( // Conditional rendering based on cabin data existence.
          <div className={styles.selectContainer}>
            {/* Container for the select dropdown and deck image. */}
            {/* CustomSelect component for selecting a deck. */}
            <CustomSelect
              className={styles.select}
              label={t("choose a deck")} // Label for the select dropdown.
              placeholder={t("select a deck")} // Placeholder text for the select dropdown.
              value={room?.deck?.code ?? ""} // Current selected deck value.
              items={decks ?? []} // Deck options for the select dropdown.
              onChange={handleDeckChange}
            />

            {/* Image displaying the selected deck. */}
            {room?.deck?.images[0] && (
              <img src={room?.deck?.images[0] ?? ""} alt={t("deck")} />
            )}

            {deck_legend && (
              <img
                className={styles.deckImage}
                src={deck_legend}
                alt="deck legend"
              />
            )}
          </div>
        ) : (
          <p>{t("No data to display")}</p>
        )}
      </div>
    </LoadingContainer>
  );
}

// Exporting the DeckPlans component as default.
export default DeckPlans;
