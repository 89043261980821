import CustomLink from "../../shared/CustomLink"; // Import CustomLink component

import { useTypedSelector } from "../../../store/store";

import styles from "./index.module.scss"; // Import styles specific to the Logo component

// Interface defining props for the Logo component
interface ILogoProps {
  pathname?: string; // Optional: Pathname for the CustomLink component
}

// Interface defining props for the LogoContainer component
interface ILogoContainerProps {
  pathname?: string; // Optional: Pathname for the CustomLink component
  className: string; // Required: CSS class name for styling the container
}

/**
 * Represents a container component for the logo.
 *
 * @param {string} pathname - Pathname for the CustomLink component.
 * @param {string} className - CSS class name for styling the container.
 * @param {React.ReactNode} children - Nested components or elements.
 * @returns {JSX.Element} LogoContainer component.
 */
function LogoContainer({
  pathname,
  className,
  children,
}: React.PropsWithChildren<ILogoContainerProps>) {
  if (pathname) {
    return (
      <CustomLink to={pathname} className={className}>
        {children}
      </CustomLink>
    );
  }

  return <div className={className}>{children}</div>;
}

/**
 * Represents a logo component.
 *
 * @param {boolean} [withText=false] - Indicates whether the logo should include text.
 * @param {string} pathname - Pathname for the LogoContainer component.
 * @returns {JSX.Element} Logo component.
 */
function Logo({ pathname }: ILogoProps) {
  const { logo_url } = useTypedSelector((state) => state.environment);

  if (!logo_url) {
    return null;
  }

  return (
    <LogoContainer className={styles.container} pathname={pathname}>
      {/* Render the logo icon */}
      <img src={logo_url} className={styles.logo} alt="logo" />
    </LogoContainer>
  );
}

// Export the Logo component as default
export default Logo;
