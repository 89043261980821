import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

export interface ITile {
  background_image: string;
  description_text: string;
  link_url: string;
  title: string;
  background_color: string;
}

// Interface defining the structure of environment variables
interface Variables {
  api_url: string;
  pos_url: string;
  search_url: string;
  payment_url: string;
  api_username: string;
  api_password: string;
  payment_api_path: string;
  date_format: string;
  app_language: string;
  api_language: string;
  breadcrumbs_text: string;
  search_item_image_source: "ship" | "cruise";
  authorize_api_login_id: string;
  authorize_client_key: string;
  privacy_policy: string;
  payment_checkbox_1: string;
  payment_checkbox_2: string;
  payment_checkbox_3: string;
  payment_checkbox_4: string;
  payment_checkbox_5: string;
  subtract_gft: boolean;
  strip_no_stop_itinerary_items: boolean;
  show_pricing_breakdown: boolean;
  check_email_uniqueness: boolean;
  authorize_is_test: boolean;
  authorize_is_mine: boolean;
  primary_color: string;
  secondary_color: string;
  text_primary_color: string;
  text_secondary_color: string;
  input_border_color: string;
  input_background_color: string;
  input_placeholder_color: string;
  input_text_color: string;
  input_focus_border_color: string;
  error_color: string;
  menu_background_color: string;
  menu_text_color: string;
  element_background_primary: string;
  element_background_secondary: string;
  element_background_dark: string;
  background_color: string;
  link_color: string;
  section_background_color: string;
  shadow_color: string;
  subtext_color: string;
  delimiter_line_color: string;
  svg_button_color: string;
  svg_icons_primary: string;
  svg_icons_secondary: string;
  search_button_background: string;
  pagination_type: string;
  pagination_count: number;
  skip_payment_step: boolean;
  agency_commission: number;
  api_accounts: string;
  agency_name: string;
  logo_url: string;
  welcome_title: string;
  favicon: string;
  auth_page_background: string;
  forgotten_password_page_background: string;
  start_booking_section_background: string;
  current_bookings_section_background: string;
  previous_bookings_section_background: string;
  hero_section_background: string;
  agency_account_type: string;
  start_booking_title: string;
  start_booking_description: string;
  current_bookings_title: string;
  current_bookings_description: string;
  previous_bookings_title: string;
  previous_bookings_description: string;
  start_page_title: string;
  current_page_title: string;
  previous_page_title: string;
  yacht_name_placeholder: string;
  number_stateroom_page_description: string;
  gft_additional_text: string;
  pnr_column_name: string;
  cruise_column_name: string;
  agent_home_tiles: string;
  destination_input_placeholder: string;
  bin_restriction: string;
  full_deck_plan_link: string;
  deck_legend: string;
  show_cancellation_policy: boolean;
  passenger_continue_text: string;
  payment_option_text: string;
  is_manual_payment_allowed: boolean;
  registration_link_text: string;
  current_booking_payment_info: string;
  payment_instruction_info: string;
  new_booking_payment_info: string;
  header_background_color: string;
  header_text_color: string;
  footer_background_color: string;
  footer_text_color: string;
  background_fade_color: string;
}

// Interface representing the state of the environment variables
export interface EnvironmentState extends Variables {
  isInitialized: boolean;
}

// Initial state of the environment variables
const initialState: EnvironmentState = {
  api_url: "",
  pos_url: "",
  search_url: "",
  payment_url: "",
  api_username: "",
  api_password: "",
  payment_api_path: "",
  date_format: "",
  app_language: "",
  api_language: "",
  breadcrumbs_text: "",
  search_item_image_source: "ship",
  authorize_api_login_id: "",
  authorize_client_key: "",
  privacy_policy: "",
  payment_checkbox_1: "",
  payment_checkbox_2: "",
  payment_checkbox_3: "",
  payment_checkbox_4: "",
  payment_checkbox_5: "",
  subtract_gft: true,
  strip_no_stop_itinerary_items: true,
  show_pricing_breakdown: true,
  check_email_uniqueness: true,
  authorize_is_test: true,
  authorize_is_mine: true,
  primary_color: "",
  secondary_color: "",
  text_primary_color: "",
  text_secondary_color: "",
  input_border_color: "",
  input_background_color: "",
  input_placeholder_color: "",
  input_text_color: "",
  input_focus_border_color: "",
  error_color: "",
  menu_background_color: "",
  menu_text_color: "",
  element_background_primary: "",
  element_background_secondary: "",
  element_background_dark: "",
  background_color: "",
  link_color: "",
  section_background_color: "",
  shadow_color: "",
  subtext_color: "",
  delimiter_line_color: "",
  svg_button_color: "",
  svg_icons_primary: "",
  svg_icons_secondary: "",
  search_button_background: "",
  pagination_type: "default",
  pagination_count: 10,
  skip_payment_step: false,
  isInitialized: false,
  agency_commission: 0,
  api_accounts: "",
  agency_name: "",
  logo_url: "",
  welcome_title: "",
  favicon: "",
  auth_page_background: "",
  forgotten_password_page_background: "",
  start_booking_section_background: "",
  current_bookings_section_background: "",
  previous_bookings_section_background: "",
  hero_section_background: "",
  agency_account_type: "",
  start_booking_title: "",
  start_booking_description: "",
  current_bookings_title: "",
  current_bookings_description: "",
  previous_bookings_title: "",
  previous_bookings_description: "",
  start_page_title: "",
  current_page_title: "",
  previous_page_title: "",
  yacht_name_placeholder: "",
  number_stateroom_page_description: "",
  gft_additional_text: "",
  pnr_column_name: "",
  cruise_column_name: "",
  agent_home_tiles: "",
  destination_input_placeholder: "",
  bin_restriction: "",
  full_deck_plan_link: "",
  deck_legend: "",
  show_cancellation_policy: false,
  passenger_continue_text: "",
  payment_option_text: "",
  is_manual_payment_allowed: false,
  registration_link_text: "",
  current_booking_payment_info: "",
  payment_instruction_info: "",
  new_booking_payment_info: "",
  header_background_color: "",
  header_text_color: "",
  footer_background_color: "",
  footer_text_color: "",
  background_fade_color: "",
};

// Creating a slice for environment variables
const environmentSlice = createSlice({
  name: "environment", // Setting the name of the slice
  initialState, // Setting the initial state
  reducers: {
    // Defining reducer functions
    setEnvVariables(state: EnvironmentState, action: PayloadAction<Variables>) {
      state.api_url = action.payload.api_url;
      state.pos_url = action.payload.pos_url;
      state.search_url = action.payload.search_url;
      state.payment_url = action.payload.payment_url;
      state.payment_api_path = action.payload.payment_api_path;
      state.date_format = action.payload.date_format;
      state.app_language = action.payload.api_language;
      state.api_language = action.payload.api_language;
      state.breadcrumbs_text = action.payload.breadcrumbs_text;
      state.authorize_api_login_id = action.payload.authorize_api_login_id;
      state.authorize_client_key = action.payload.authorize_client_key;
      state.privacy_policy = action.payload.privacy_policy;
      state.payment_checkbox_1 = action.payload.payment_checkbox_1;
      state.payment_checkbox_2 = action.payload.payment_checkbox_2;
      state.payment_checkbox_3 = action.payload.payment_checkbox_3;
      state.payment_checkbox_4 = action.payload.payment_checkbox_4;
      state.payment_checkbox_5 = action.payload.payment_checkbox_5;
      state.subtract_gft = action.payload.subtract_gft;
      state.show_pricing_breakdown = action.payload.show_pricing_breakdown;
      state.check_email_uniqueness = action.payload.check_email_uniqueness;
      state.authorize_is_test = action.payload.authorize_is_test;
      state.authorize_is_mine = action.payload.authorize_is_mine;
      state.primary_color = action.payload.primary_color;
      state.secondary_color = action.payload.secondary_color;
      state.text_primary_color = action.payload.text_primary_color;
      state.text_secondary_color = action.payload.text_secondary_color;
      state.input_border_color = action.payload.input_border_color;
      state.input_background_color = action.payload.input_background_color;
      state.input_placeholder_color = action.payload.input_placeholder_color;
      state.input_text_color = action.payload.input_text_color;
      state.input_focus_border_color = action.payload.input_focus_border_color;
      state.error_color = action.payload.error_color;
      state.menu_background_color = action.payload.menu_background_color;
      state.menu_text_color = action.payload.menu_text_color;
      state.element_background_dark = action.payload.element_background_dark;
      state.background_color = action.payload.background_color;
      state.link_color = action.payload.link_color;
      state.section_background_color = action.payload.section_background_color;
      state.shadow_color = action.payload.shadow_color;
      state.subtext_color = action.payload.subtext_color;
      state.delimiter_line_color = action.payload.delimiter_line_color;
      state.svg_button_color = action.payload.svg_button_color;
      state.svg_icons_primary = action.payload.svg_icons_primary;
      state.search_button_background = action.payload.search_button_background;
      state.pagination_type = action.payload.pagination_type;
      state.skip_payment_step = action.payload.skip_payment_step;
      state.agency_commission = action.payload.agency_commission;
      state.api_accounts = action.payload.api_accounts;
      state.agency_name = action.payload.agency_name;
      state.logo_url = action.payload.logo_url;
      state.welcome_title = action.payload.welcome_title;
      state.favicon = action.payload.favicon;
      state.auth_page_background = action.payload.auth_page_background;
      state.hero_section_background = action.payload.hero_section_background;
      state.agency_account_type = action.payload.agency_account_type;
      state.gft_additional_text = action.payload.gft_additional_text;
      state.pnr_column_name = action.payload.pnr_column_name;
      state.cruise_column_name = action.payload.cruise_column_name;
      state.agent_home_tiles = action.payload.agent_home_tiles;
      state.start_booking_title = action.payload.start_booking_title;
      state.current_bookings_title = action.payload.current_bookings_title;
      state.previous_bookings_title = action.payload.previous_bookings_title;
      state.start_page_title = action.payload.start_page_title;
      state.current_page_title = action.payload.current_page_title;
      state.previous_page_title = action.payload.previous_page_title;
      state.yacht_name_placeholder = action.payload.yacht_name_placeholder;
      state.bin_restriction = action.payload.bin_restriction;
      state.full_deck_plan_link = action.payload.full_deck_plan_link;
      state.deck_legend = action.payload.deck_legend;
      state.show_cancellation_policy = action.payload.show_cancellation_policy;
      state.payment_instruction_info = action.payload.payment_instruction_info;
      state.new_booking_payment_info = action.payload.new_booking_payment_info;
      state.header_background_color = action.payload.header_background_color;
      state.header_text_color = action.payload.header_text_color;
      state.footer_background_color = action.payload.footer_background_color;
      state.footer_text_color = action.payload.footer_text_color;
      state.background_fade_color = action.payload.background_fade_color;

      state.current_booking_payment_info =
        action.payload.current_booking_payment_info;

      state.start_booking_section_background =
        action.payload.start_booking_section_background;

      state.forgotten_password_page_background =
        action.payload.forgotten_password_page_background;

      state.current_bookings_section_background =
        action.payload.current_bookings_section_background;

      state.previous_bookings_section_background =
        action.payload.previous_bookings_section_background;

      state.search_item_image_source =
        action.payload.search_item_image_source || "ship";

      state.element_background_primary =
        action.payload.element_background_primary;

      state.element_background_secondary =
        action.payload.element_background_secondary;

      state.strip_no_stop_itinerary_items =
        action.payload.strip_no_stop_itinerary_items;

      state.start_booking_description =
        action.payload.start_booking_description;

      state.current_bookings_description =
        action.payload.current_bookings_description;

      state.previous_bookings_description =
        action.payload.previous_bookings_description;

      state.pagination_count = isNaN(Number(action.payload.pagination_count))
        ? 10
        : Number(action.payload.pagination_count);

      state.number_stateroom_page_description =
        action.payload.number_stateroom_page_description;

      state.destination_input_placeholder =
        action.payload.destination_input_placeholder;

      state.is_manual_payment_allowed =
        action.payload.is_manual_payment_allowed;

      state.isInitialized = true;
      state.passenger_continue_text = action.payload.passenger_continue_text;
      state.payment_option_text = action.payload.payment_option_text;
      state.registration_link_text =
        action.payload.registration_link_text || "";
    },
  },
});

// Exporting action creators
export const { setEnvVariables } = environmentSlice.actions;

// Exporting reducer function
export default environmentSlice.reducer;
