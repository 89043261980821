import { useState, ChangeEvent } from "react";
import { Form } from "react-bootstrap";

interface Option {
  code: string | number;
  name: string | number;
}

interface DropDownProps {
  defaultValue?: string | number;
  parentField?: string;
  index?: number;
  onSelection: (
    event: string | ChangeEvent<HTMLSelectElement>,
    index?: number | undefined,
    parentField?: string | undefined,
  ) => void;
  ddName?: string;
  className?: string;
  hideEmptyValue?: boolean;
  data: Option[];
}

function DropDown({
  defaultValue,
  parentField,
  index,
  onSelection,
  ddName,
  className,
  hideEmptyValue,
  data,
}: DropDownProps): any {
  const [value, setValue] = useState<string | number | undefined>(defaultValue);

  const handleSelection = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    if (parentField !== undefined) {
      onSelection(selectedValue, index, parentField);
    } else {
      onSelection(selectedValue);
    }
    setValue(selectedValue);
  };

  return (
    <Form.Group>
      <Form.Select
        onChange={handleSelection}
        id={`${ddName}${index}`}
        className={className}
        name={ddName}
        value={value}>
        {!hideEmptyValue && <option value="">Select...</option>}

        {data.map(({ code, name }, index) => (
          <option key={index} value={String(code)}>
            {name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default DropDown;
