// React hooks for managing refs and lifecycle.
import {
  ForwardedRef, // Type for forwarding ref.
  MutableRefObject, // Type for mutable object refs.
  forwardRef, // HOC for forwarding refs in function components.
  useImperativeHandle, // Hook for customizing the instance value exposed to parent components.
  useMemo, // Hook for memorizing values.
  useRef, // Hook for accessing DOM elements or storing mutable values across renders.
} from "react";

// Helper function to create an array range, useful for generating guest numbers.
import { createArrayRange } from "../../../../../utils/helpers/createArrayRange";
// Slice for managing guest fields within the Redux store.
import { GuestFields } from "../../../../../store/slices/guestsSlice";

// Component for individual room form.
import RoomForm from "../RoomForm";

// Module CSS for styling.
import styles from "./index.module.scss";

// Props definition for the RoomForms component.
interface IRoomFormsProps {
  isIncludesLead: boolean; // Flag indicating if the lead guest form should be included.
  roomNumber: number; // The current room number.
  guestsAmount: number; // Total number of guests in the room.
}

// Output type definition for the validation results.
type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields; // The guest inputs.
      errors: GuestFields | null; // Any validation errors.
    }
  >
>;

// Interface for the methods exposed by RoomForms via ref.
interface RoomFormsRef {
  validateForms: () => ValidateFormsOutput; // Function to validate all forms in the room.
}

// ForwardRef component definition to allow parent components to invoke functions within RoomForms.
const RoomForms = forwardRef<RoomFormsRef, IRoomFormsProps>(function RoomForms(
  { isIncludesLead, roomNumber, guestsAmount }: IRoomFormsProps,
  ref: ForwardedRef<RoomFormsRef>,
) {
  // Create a range based on the number of guests, useful for iterating guest forms.
  const guestsRange = useMemo(() => createArrayRange(1, guestsAmount ?? 1), []);

  // Refs storage for all RoomForm components.
  const roomFormsRefs: Record<
    number,
    MutableRefObject<{
      expand: () => void; // Method to expand a form.
      scrollIntoView: () => void; // Method to scroll the form into view.
      validateForm: () => {
        inputs: GuestFields; // Inputs from the form.
        errors: GuestFields | null; // Validation errors from the form.
      };
    } | null>
  > = {};

  // Function to validate all RoomForm components and aggregate their results.
  const validateForms = () => {
    const output: ValidateFormsOutput = { [roomNumber]: {} };

    Object.values(roomFormsRefs).forEach((formRef, index) => {
      const validationResult = formRef.current?.validateForm();

      if (validationResult) {
        output[roomNumber][index + 1] = {
          ...formRef.current,
          ...validationResult,
        };
      }
    });

    return output;
  };

  // Expose validateForms method to parent components through ref.
  useImperativeHandle(ref, () => ({ validateForms }), []);

  // Render RoomForm components for each guest.
  return (
    <div className={styles.container}>
      {guestsRange.map((guestNumber) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const ref = useRef(null);

        roomFormsRefs[guestNumber] = ref;

        return (
          <RoomForm
            key={guestNumber}
            ref={ref}
            isLead={isIncludesLead && guestNumber === 1} // Mark the first guest as lead if applicable.
            guestNumber={guestNumber}
            roomNumber={roomNumber}
          />
        );
      })}
    </div>
  );
});

export default RoomForms;
