import { useTypedSelector } from "../../../../../store/store";

import styles from "./index.module.scss";

function DeckPlansModal() {
  const { data } = useTypedSelector((state) => state.modal);

  const url = data as string;

  return (
    <div className={styles.container}>
      <img className={styles.image} src={url} alt="deck plan" />
    </div>
  );
}

export default DeckPlansModal;
