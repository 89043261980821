import { useEffect, useMemo, useRef, useState } from "react";

import { useLazyRetrieveReservationQuery } from "../../../../../store/services/ReservationService";
import { useLazyCreateOpaquePaymentQuery } from "../../../../../store/services/PaymentService";
import { resetReservationToInitial } from "../../../../../store/slices/paymentModalSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store";
import citizenshipList from "../../../../../utils/constants/citizenshipList";
import { currencyToFormat } from "../../../../../utils/helpers/currency";
import getMonthsList from "../../../../../utils/helpers/getMonthsList";
import getYearsList from "../../../../../utils/helpers/getYearsList";
import { showToast } from "../../../../../store/slices/toastSlice";
import { hideModal } from "../../../../../store/slices/modalSlice";
import TOAST from "../../../../../utils/constants/toast";
import useScript from "../../../../../hooks/useScript";

import {
  validateCreditCard,
  validateCVV,
  validateDate,
} from "../../../../../utils/validation/functions/payment";

import CustomSelect from "../../../../shared/CustomSelect";
import SearchInput from "../../../../shared/SearchInput";
import LoadingContainer from "../../../LoadingContainer";
import Checkbox from "../../../../shared/Checkbox";
import Button from "../../../../shared/Button";
import Input from "../../../../shared/Input";

import styles from "./index.module.scss";
import Alert from "../../../../shared/Alert";

interface AcceptObject {
  dispatchData?: (secureData: any, responseHandler: any) => void;
}

const COUNTRY_LIST = citizenshipList
  .map((el) => ({
    label: el.country,
    value: el.iso_2,
  }))
  .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

function PaymentModal() {
  const [createOpaqueData] = useLazyCreateOpaquePaymentQuery();
  const [getReservation] = useLazyRetrieveReservationQuery();
  const dispatch = useTypedDispatch();

  const { reservation } = useTypedSelector((state) => state.paymentModal);
  const { type, data: pnr } = useTypedSelector((state) => state.modal);
  const { sessionKey, source } = useTypedSelector((state) => state.session);

  const { agency: channel_partner, agent: agency } = useTypedSelector(
    (state) => state.session,
  );

  const {
    payment_instruction_info,
    authorize_api_login_id,
    authorize_client_key,
    authorize_is_mine,
    authorize_is_test,
    bin_restriction,
  } = useTypedSelector((state) => state.environment);

  const [countryListForm, setCountryListForm] = useState(COUNTRY_LIST);
  const [amount, setAmount] = useState({ value: 0, errorMessage: "" });
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);

  const [date, setDate] = useState({
    month: { value: "", errorMessage: "" },
    year: { value: "", errorMessage: "" },
  });

  const [card, setCard] = useState({
    cardNumber: { value: "", errorMessage: "" },
    expirationDate: {
      value: "",
      errorMessage: "",
    },
    cvv: { value: "", errorMessage: "" },
  });

  const isLoadedRef = useRef<boolean>(false);

  const isUseAcceptCore = useMemo(
    () => [true, "true"].includes(authorize_is_mine),
    [authorize_is_mine],
  );

  const scriptUrl = useMemo(
    () =>
      [true, "true"].includes(authorize_is_test)
        ? "https://jstest.authorize.net/v1/Accept.js"
        : "https://js.authorize.net/v1/Accept.js",
    [authorize_is_test],
  );

  useScript(scriptUrl);

  const activeRestriction = useMemo(() => {
    const parsedRestrictions: Array<{
      number: string[];
      errorMessage: string;
    }> = JSON.parse(bin_restriction || "[]");

    const matchedRestriction = parsedRestrictions.find((bin) =>
      bin.number.some((number) => card.cardNumber.value.startsWith(number)),
    );

    return matchedRestriction;
  }, [card.cardNumber.value]);

  const monthList = useMemo(() => {
    return getMonthsList().map((item) => ({ value: item, label: item }));
  }, []);

  const yearList = useMemo(() => {
    return getYearsList(20, 0)
      .sort((a, b) => b - a)
      .map((item) => ({
        value: String(item),
        label: String(item),
      }));
  }, []);

  const isFormValid = useMemo(() => {
    const isAmountValid = !!amount.value && !amount.errorMessage;

    const isNumberValid =
      !!card.cardNumber.value && !card.cardNumber.errorMessage;

    const isExpDateValid =
      !!card.expirationDate.value && !card.expirationDate.errorMessage;

    const isCvvValid = !!card.cvv.value && !card.cvv.errorMessage;

    return isAmountValid && isNumberValid && isExpDateValid && isCvvValid;
  }, [amount, card]);

  const transactions: Array<{
    currency: string;
    amount: number;
    transaction_type: string;
    made_by: string;
    tokenized: null;
  }> = useMemo(
    () => [
      {
        currency: reservation?.currency ?? "USD",
        amount: amount.value,
        transaction_type: "creditcard",
        made_by: "Website Authorize.net",
        tokenized: null,
      },
    ],
    [reservation, amount],
  );

  const handleAmountChange = ({ value }: { value: string }) => {
    const numValue = +value;

    setAmount((prev) => ({ ...prev, value: numValue, errorMessage: "" }));
  };

  const handleExpirationDateMonth = ({ value }: { value: string }) => {
    setDate((prev) => ({ ...prev, month: { value, errorMessage: "" } }));
  };

  const handleExpirationDateYear = ({ value }: { value: string }) => {
    setDate((prev) => ({ ...prev, year: { value, errorMessage: "" } }));
  };

  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey: string;
  }) => {
    setCard((prev) => ({ ...prev, [valueKey]: { errorMessage: "", value } }));
  };

  const setErrors = ({
    cardNumber,
    cvv,
    expirationDate,
    combinedLength,
  }: {
    cardNumber: boolean | string;
    cvv: boolean | string;
    expirationDate: boolean | string;
    combinedLength: boolean;
  }) => {
    setCard((prev) => {
      const output = structuredClone(prev);

      if (typeof cardNumber === "string") {
        output.cardNumber.errorMessage = cardNumber;
      } else if (!cardNumber) {
        output.cardNumber.errorMessage = "Card number is not valid";
      }

      if (typeof cvv === "string") {
        output.cvv.errorMessage = cvv;
      } else if (!cvv) {
        output.cvv.errorMessage = "CVV number is not valid";
      }

      if (typeof expirationDate === "string") {
        output.expirationDate.errorMessage = expirationDate;
      } else if (!expirationDate) {
        output.expirationDate.errorMessage = "Date is not valid";
      }

      if (!combinedLength) {
        output.cvv.errorMessage =
          "Combined length of card number and CVV should be 19";
      }

      return output;
    });
  };

  const handleSearchInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey?: string;
  }) => {
    switch (valueKey) {
      case "country": {
        const filterCountryResult = COUNTRY_LIST.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setCountryListForm(filterCountryResult);
        break;
      }

      default: {
        break;
      }
    }
  };

  const acceptDataHandler = async (payResponse: {
    opaqueData: { dataValue: string };
  }) => {
    try {
      const clonedTransictions = structuredClone(transactions[0]);

      const response = await createOpaqueData({
        session: sessionKey,
        pnr,
        source,
        transactions: [
          {
            ...clonedTransictions,
            tokenized: payResponse.opaqueData.dataValue,
          },
        ],
      });

      if (response.error && response.isError) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: "Payment failed",
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      } else {
        dispatch(hideModal());

        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: "Payment success",
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Payment failed",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      console.error("opaqueDataHandler error:", error);
    } finally {
      setIsFormLoading(false);
    }
  };

  const makeOpaqueRequet = async () => {
    try {
      const cardData = {
        cardNumber: card.cardNumber.value,
        year: card.expirationDate.value.split("-")[0],
        month: card.expirationDate.value.split("-")[1],
        cardCode: card.cvv.value,
      };

      const opaqueTransactionRequest = [
        {
          currency: transactions[0].currency ?? "USD",
          amount: transactions[0].amount,
          transaction_type: "creditcard",
          made_by: "Website",
          card: {
            pan: cardData.cardNumber,
            expiry_month: cardData.month,
            expiry_year: cardData.year,
            cvv: cardData.cardCode,
            name: `${firstName} ${lastName}`,
            address: {
              address:
                reservation.sailings[0].cabins[0].guests[0].address.join(", "),
              city,
              state,
              zip,
              country,
            },
            email: reservation.lead_email,
            phone: reservation.lead_phone,
          },
        },
      ];

      const response = await createOpaqueData({
        session: sessionKey,
        pnr,
        source,
        transactions: opaqueTransactionRequest,
      });

      if (response.data.errors && response.isError) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: "Payment failed",
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      } else {
        dispatch(hideModal());

        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: "Payment success",
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Payment failed",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      console.error("opaqueDataHandler error:", error);
    } finally {
      setIsFormLoading(false);
    }
  };

  const payHandler = async () => {
    const authData = {
      apiLoginID: authorize_api_login_id,
      clientKey: authorize_client_key,
    };

    const cardData = {
      cardNumber: card.cardNumber.value,
      year: card.expirationDate.value.split("-")[0],
      month: card.expirationDate.value.split("-")[1],
      cardCode: card.cvv.value,
    };

    const acceptTransactionRequest = {
      amount: transactions[0].amount,
      transactionType: "authCaptureTransaction",
      description: "Cabin booking payment",
      refId: pnr,
      billTo: {
        firstName,
        lastName,
        city,
        state,
        zip,
        country,
      },
    };

    const secureData = {
      authData,
      cardData,
      transactionRequest: acceptTransactionRequest,
    };

    setIsFormLoading(true);

    if ("Accept" in window && isUseAcceptCore) {
      const accept: AcceptObject = window.Accept as AcceptObject;

      if (accept.dispatchData && typeof accept.dispatchData === "function") {
        accept.dispatchData(secureData, acceptDataHandler);
      }
    } else {
      makeOpaqueRequet();
    }
  };

  const validateForm = () => {
    const cardNumber = validateCreditCard(card.cardNumber.value);
    const cvv = validateCVV(card.cvv.value);
    const expirationDate = validateDate(card.expirationDate.value);

    const combinedLength =
      card.cardNumber.value.replace(/\s/g, "").length +
        card.cvv.value.length ===
      19;

    const isAmountValid = amount.value >= min && amount.value <= max;

    if (!isAmountValid && amount.value < min) {
      setAmount((prev) => ({
        ...prev,
        errorMessage: `The minimum payment amount allowed is ${currencyToFormat(min, reservation.currency)} to confirm the booking`,
      }));
    }

    if (!isAmountValid && amount.value > max) {
      setAmount((prev) => ({
        ...prev,
        errorMessage: `You cannot overpay this booking, the maximum amount allowed is ${currencyToFormat(max, reservation.currency)}`,
      }));
    }

    if (!cardNumber || !cvv || !expirationDate || !combinedLength) {
      setErrors({
        cardNumber,
        cvv,
        expirationDate,
        combinedLength,
      });
    } else if (isAmountValid) {
      payHandler();
    }
  };

  const initReservation = () => {
    const totalRemaining = +reservation.total_price - +reservation.total_paid;

    const depositAmount = reservation.payment_schedule
      ? Math.max(
          ...reservation.payment_schedule.map((schedule) => +schedule.amount),
        )
      : 0;

    const depositMin =
      totalRemaining !== 0 && reservation.payment_schedule
        ? Math.min(
            ...reservation.payment_schedule.map((schedule) => +schedule.amount),
          )
        : 0;

    if (reservation.status === "option") {
      if (depositAmount > 0) {
        setMin(depositMin);
        setMax(totalRemaining);
        setAmount({
          value: +reservation.next_payment_outstanding,
          errorMessage: "",
        });
      } else {
        setMin(depositMin);
        setMax(totalRemaining);
        setAmount({
          value: +reservation.next_payment_outstanding,
          errorMessage: "",
        });
      }
    } else {
      setMin(depositMin);
      setMax(totalRemaining);
      setAmount({
        value: +reservation.next_payment_outstanding,
        errorMessage: "",
      });
    }
  };

  const leadPassengersAddress = () => {
    setFirstName(reservation.sailings[0].cabins[0].guests[0].given_name);
    setLastName(reservation.sailings[0].cabins[0].guests[0].lastname);
    setCity(reservation.sailings[0].cabins[0].guests[0].city);
    setState(reservation.sailings[0].cabins[0].guests[0].state);
    setZip(reservation.sailings[0].cabins[0].guests[0].zip_code);
    setCountry(reservation.sailings[0].cabins[0].guests[0].country);
  };

  useEffect(() => {
    (async () => {
      if (pnr && !isLoadedRef.current) {
        await getReservation({ pnr, channel_partner, agency });

        isLoadedRef.current = true;
      }
    })();
  }, [pnr]);

  useEffect(() => {
    if (reservation.pnr) {
      initReservation();
      leadPassengersAddress();
    }
  }, [reservation]);

  useEffect(() => {
    if (type !== "" && reservation.pnr !== "") {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [type, reservation]);

  useEffect(() => {
    setCard((prevState) => ({
      ...prevState,
      expirationDate: {
        ...prevState.expirationDate,
        value:
          Boolean(date.year.value) && Boolean(date.month.value)
            ? `${date.year.value}-${date.month.value}`
            : "",
      },
    }));
  }, [date.month.value, date.year.value]);

  useEffect(() => {
    if (activeRestriction) {
      setCard((prev) => ({
        ...prev,
        cardNumber: {
          ...prev.cardNumber,
          errorMessage: activeRestriction.errorMessage,
        },
      }));
    } else {
      setCard((prev) => ({
        ...prev,
        cardNumber: {
          ...prev.cardNumber,
          errorMessage: "",
        },
      }));
    }
  }, [activeRestriction]);

  useEffect(() => {
    if (type === "") {
      dispatch(resetReservationToInitial());
    }
  }, [type]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className={styles.container}>
        {Boolean(payment_instruction_info) && (
          <Alert message={payment_instruction_info} />
        )}

        <form className={styles.form} onSubmit={validateForm}>
          <div className={styles.inputs}>
            <Input
              className={styles.input}
              value={`${amount.value}`}
              label="Amount"
              placeholder="Enter amount"
              type="number"
              errorMessage={amount.errorMessage}
              onChange={handleAmountChange}
              showError
            />
          </div>

          <div className={styles.inputs}>
            <Input
              className={styles.input}
              value={card.cardNumber.value}
              valueKey="cardNumber"
              label="Card number"
              placeholder="Enter 16 digits"
              errorMessage={card.cardNumber.errorMessage}
              onChange={handleInputChange}
              showError
            />
          </div>

          <div className={styles.inputsDate}>
            <CustomSelect
              className={styles.input}
              value={date.month.value}
              label="month"
              errorMessage={card.expirationDate.errorMessage}
              items={monthList}
              placeholder="Month"
              onChange={handleExpirationDateMonth}
            />

            <CustomSelect
              className={styles.input}
              value={date.year.value}
              label="year"
              errorMessage={card.expirationDate.errorMessage}
              items={yearList.reverse()}
              placeholder="Year"
              onChange={handleExpirationDateYear}
            />
          </div>

          <div className={styles.inputs}>
            <Input
              className={styles.input}
              value={card.cvv.value}
              valueKey="cvv"
              label="Card Code"
              placeholder="3 digits"
              errorMessage={card.cvv.errorMessage}
              onChange={handleInputChange}
              showError
            />
          </div>

          <Checkbox
            label="not the same address as lead guest"
            value={showForm}
            onChange={() => setShowForm((prev) => !prev)}
          />

          {showForm && (
            <>
              <div className={styles.inputsDate}>
                <Input
                  className={styles.input}
                  value={firstName}
                  label="First Name"
                  placeholder="Enter First Name"
                  onChange={({ value }) => setFirstName(value)}
                />

                <Input
                  className={styles.input}
                  value={lastName}
                  label="Last Name"
                  placeholder="Enter Last Name"
                  onChange={({ value }) => setLastName(value)}
                />
              </div>

              <div className={styles.inputsDate}>
                <Input
                  className={styles.input}
                  value={city}
                  label="City"
                  placeholder="Enter City"
                  onChange={({ value }) => setCity(value)}
                />

                <Input
                  className={styles.input}
                  value={state}
                  label="State"
                  placeholder="Enter State"
                  onChange={({ value }) => setState(value)}
                />
              </div>

              <div className={styles.inputsDate}>
                <Input
                  className={styles.input}
                  value={zip}
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  onChange={({ value }) => setZip(value)}
                />

                <SearchInput
                  className={styles.input}
                  value={country}
                  valueKey="country"
                  displayKey="label"
                  results={countryListForm}
                  label="country"
                  placeholder="Select a country"
                  isMultiple={false}
                  onChange={handleSearchInputChange}
                  onChosenChange={({ value, valueKey }) =>
                    handleInputChange({ value: value.label, valueKey })
                  }
                  isRequired
                />
              </div>
            </>
          )}

          <div className={styles.inputs}>
            <Button
              onClick={validateForm}
              className={styles.button}
              label="Pay"
              type="submit"
              loading={isFormLoading}
              disabled={!isFormValid || isFormLoading}
            />
          </div>
        </form>
      </div>
    </LoadingContainer>
  );
}

export default PaymentModal;
