// React hooks and utilities for managing component behavior and forwarding references.
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
// React Router hooks for navigation and accessing URL parameters.
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// Internationalization hook for supporting multiple languages.
import { useTranslation } from "react-i18next";

// Custom hook for making a lazy query to create a reservation.
import { useLazyCreateReservationQuery } from "../../../../../store/services/CabinSelectService";
// Hooks for dispatching actions to Redux store and accessing its state.
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store";
// Redux slice actions for updating guests and room states, and for showing toasts.
import { GuestFields } from "../../../../../store/slices/guestsSlice";
import { updateRooms } from "../../../../../store/slices/roomsSlice";
import { showToast } from "../../../../../store/slices/toastSlice";
// Utility function for preparing URL parameters.
import { prepareParams } from "../../../../../utils/helpers/rooms";
// Constants for toast notifications.
import TOAST from "../../../../../utils/constants/toast";

// Reusable UI components for form submission.
import Button from "../../../../shared/Button";

// CSS module for styling.
import styles from "./index.module.scss";

// Type definitions for component props and the submission payload.
interface ISubmitButtonProps {
  onClick: () => void;
}

type Documents = Array<{
  type: string;
  number: string;
  issue_date: string;
  expiry_date: string;
  place_of_birth: string;
  country_of_issue: string;
  gender: string;
}>;

type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

const SubmitButton = forwardRef(function SubmitButton(
  { onClick }: ISubmitButtonProps,
  ref: ForwardedRef<{
    submit: (guests: SubmitButtonPayload) => void;
  }>,
) {
  // Hook invocations for translation, navigation, dispatching, and Redux state selection.
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const { api_language } = useTypedSelector((state) => state.environment);
  const { skip_payment_step } = useTypedSelector((state) => state.environment);

  // Custom hook invocation for the lazy query to create a reservation.
  const [reservation] = useLazyCreateReservationQuery();

  // React Router hooks for accessing dynamic URL parameters.
  const { cruiseId } = useParams();
  const [searchParams] = useSearchParams();
  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  // Selectors for accessing rooms and cruise details from Redux store.
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { cruise } = useTypedSelector((state) => state.search);

  const { api_language: language } = useTypedSelector(
    (state) => state.environment,
  );

  // State for managing loading indicator of the submission process.
  const [isLoading, setIsLoading] = useState(false);

  const generatePassportDetails = (guestFields: GuestFields): Documents => {
    const documents: Documents = [
      {
        type: "passport",
        number: guestFields.passport_number,
        issue_date: guestFields.issue_date,
        expiry_date: guestFields.expiry_date,
        place_of_birth: guestFields.place_of_birth,
        country_of_issue: guestFields.country_of_issue,
        gender: guestFields.gender,
      },
    ];

    return documents;
  };

  // The submit function that encapsulates the reservation creation logic.
  const submit = async (guests: SubmitButtonPayload) => {
    setIsLoading(true);

    const payload = {
      rate_code: rooms?.[1].fare?.rate_code ?? "",
      cruiseId: cruiseId ?? "",
      source: cruise?.source ?? "",
      language,
      cabins: Object.values(rooms ?? {}).map((room, roomIndex) => ({
        cabin_number: room.cabin?.number ?? "",
        dining_seating: "",
        dining_table: "",
        grade_code: room.grade?.code ?? "",
        guests: Object.values(guests[roomIndex + 1] ?? {}).map(
          (guest, guestIndex) => ({
            address: [guest.inputs.address1, guest.inputs.address2].filter(
              (el) => el,
            ),
            city: guest.inputs.city,
            country: guest.inputs.country,
            date_of_birth: `${guest.inputs.yearOfBirth}-${guest.inputs.monthOfBirth}-${guest.inputs.dayOfBirth}`,
            email: guest.inputs.email,
            given_name: guest.inputs.given_name,
            language: api_language || "en-us",
            lastname: guest.inputs.last_name,
            last_name: guest.inputs.last_name,
            lead_passenger: guestIndex === 0 && roomIndex === 0,
            nationality: guest.inputs.nationality,
            phone: guest.inputs.phone,
            state: guest.inputs.state,
            title: guest.inputs.title,
            gender: guest.inputs.gender,
            zip_code: guest.inputs.zip_code,
            documents: generatePassportDetails(guest.inputs),
            request: guest.inputs.request,
          }),
        ),
      })),
    };

    try {
      const { data } = await reservation({ ...payload });
      const updatedStateRooms = structuredClone(rooms);

      if (data?.pnr && updatedStateRooms) {
        updatedStateRooms[1].pnr = data.pnr;

        const pathParam = skip_payment_step
          ? "payment-confirmation"
          : "payment";

        const params = prepareParams(
          searchParams,
          rooms ?? {},
          currentRoomNumber,
          currentRoomNumber < totalRooms,
        );

        dispatch(updateRooms(updatedStateRooms));
        navigate(`/search-results/${cruiseId}/${pathParam}?${params}`);
      }

      if (!data) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            duration: TOAST.DEFAULT_DURATION,
            message: t("PNR was not received from the server"),
          }),
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          duration: TOAST.DEFAULT_DURATION,
          message: t("Reservation creation failed"),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  // useImperativeHandle hook to expose the submit function to parent components.
  useImperativeHandle(ref, () => ({ submit }), [submit]);

  // Rendering the Button component with props and event handlers.
  return (
    <Button
      label={t("submit")}
      className={styles.submitButton}
      onClick={onClick}
      loading={isLoading}
      disabled={isLoading}
    />
  );
});

// Exporting the component for use in other parts of the application.
export default SubmitButton;
