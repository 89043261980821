// Importing the classNames function for convenient class name concatenation
import classNames from "classnames";
import * as Icons from "react-icons/fi";

// Importing custom styles for the component
import styles from "./index.module.scss";

// Extending BASE with any additional icons or overriding existing ones

// Defining the props interface for our SvgIcon component
interface ISvgIconProps {
  type: keyof typeof Icons; // The icon type, matching keys in SVG_BY_TYPE
  className?: string; // Optional custom class name for additional styling
  onClick?: () => void; // Optional click event handler
}

// The SvgIcon component definition
function SvgIcon({ type, className, onClick }: ISvgIconProps) {
  // Determining which icon to render based on the 'type' prop
  // eslint-disable-next-line import/namespace
  const CustomSvg = Icons[type];

  // Rendering the SVG component with optional className and onClick props
  return (
    <CustomSvg
      className={classNames(styles.icon, className)} // Combining predefined and custom class names
      onClick={onClick} // Assigning the click event handler, if provided
    />
  );
}

// Default props for the SvgIcon component
SvgIcon.defaultProps = {
  className: "", // No additional class name by default
  onClick: () => {}, // No operation for click by default
};

// Exporting SvgIcon as the default export of this module
export default SvgIcon;
